import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import Title from "./title";
import { useTranslation } from "react-i18next"; // Para la traducción

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const { t } = useTranslation();
  const [{ name, email, message }, setState] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState(""); // Estado para el mensaje de éxito
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState(initialState); // Limpia el estado del formulario
    setSuccessMessage(""); // Limpia el mensaje de éxito
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
    .sendForm("service_47frcrt", "template_9g2tz67", e.target, "zqIfvcQnJUYKMQdmB")
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage(t("Contact.successMessage")); // Mensaje de éxito
          clearState(); // Limpia el formulario y el mensaje de éxito
        },
        (error) => {
          console.log(error.text);
          setErrorMessage(t("Contact.errorMessage")); // Mensaje de error si es necesario
        }
      );
  };

  return (
    <div id="contact">
      <Title title={t("Contact.title")} />
      <div className="container contact-cont">
        <div className="col-md-8">
          <div className="section-title">
            <h2>{t("Contact.getInTouch")}</h2>
            <p>{t("Contact.fillOutForm")}</p>
          </div>
          <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder={t("Contact.namePlaceholder")}
                    required
                    value={name} // Asegura que el valor se actualice
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder={t("Contact.emailPlaceholder")}
                    required
                    value={email} // Asegura que el valor se actualice
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder={t("Contact.messagePlaceholder")}
                required
                value={message} // Asegura que el valor se actualice
                onChange={handleChange}
              ></textarea>
              <p className="help-block text-danger"></p>
            </div>
            <button type="submit" className="btn btn-custom btn-lg">
              {t("Contact.sendMessage")}
            </button>
          </form>
          {errorMessage && <div className="alert alert-error mt-3">{errorMessage}</div>} {/* Mostrar mensaje de éxito */}
          {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>} {/* Mostrar mensaje de éxito */}
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            <h3>{t("Contact.contactInfo")}</h3>
            <p>
              <span>
                <i className="fa fa-map-marker"></i> {t("Contact.address")}
              </span>
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> {t("Contact.phone")}
              </span>{" "}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> {t("Contact.phone2")}
              </span>{" "}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> {t("Contact.email")}
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a href="https://www.google.com/maps?q=29.0809106,-110.9718183" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-map-marker"></i>
                  </a>
                </li>
                <li>
                  <a href={`tel:${props.data ? props.data.phone : "1234567890"}`}>
                    <i className="fa fa-phone"></i>
                  </a>
                </li>
                <li>
                  <a href={`mailto:${props.data ? props.data.email : "example@example.com"}`}>
                    <i className="fa fa-envelope-o"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
