import { Image } from "./image";
import React, { useState, useEffect } from "react"; // Importar useState y useEffect
import { useTranslation } from 'react-i18next'; // Importar useTranslation

export const Gallery = (props) => {
  const { t } = useTranslation(); // Hook para traducción
  const [selectedText, setSelectedText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null); // Almacenar el índice seleccionado

  // Manejar el clic en la imagen
  const handleImageClick = (index) => {
    setSelectedIndex(index); // Actualiza el índice seleccionado
    const { titleText, text } = props.data.services[index];
    setSelectedText(
      <div>
        <div className="service-title">{titleText}</div>
        <p>{text}</p>
      </div>
    );
  };

  // Actualizar el texto seleccionado cuando cambia el idioma
  useEffect(() => {
    if (selectedIndex !== null) {
      const { titleText, text } = props.data.services[selectedIndex];
      setSelectedText(
        <div>
          <div className="service-title">{titleText}</div>
          <p>{text}</p>
        </div>
      );
    }
  }, [t, selectedIndex, props.data?.services]); // Dependencias: idioma y servicios

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t('Gallery.title')}</h2>
          <div className="service-text">
            {selectedText || t('Gallery.selectSector')}
          </div>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data?.services
              ? props.data.services.map((service, index) => (
                  <div
                    key={service.title} // Cambiado para usar un campo único
                    className="col-sm-6 col-md-4 col-lg-3"
                    onClick={() => handleImageClick(index)} // Llamar a la función al hacer clic
                  >
                    <Image title={service.title} smallImage={service.smallImage} />
                  </div>
                ))
              : "..."}
          </div>
        </div>
      </div>
    </div>
  );
};
