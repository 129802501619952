import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const Navigation = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "es" ? "en" : "es";
    i18n.changeLanguage(newLanguage); // Cambia el idioma
    onLanguageChange(newLanguage); // Actualiza el idioma en el App
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="top-bar">
        <div className="container">
          <div className="inner-container">
            <ul className="list-inline quick-contact pull-left">
              <li>
                <a href="tel:+526622061014">
                  <FontAwesomeIcon icon="fa-solid fa-phone" />
                  <span> | +52 662 152 8911 | </span>
                </a>
              </li>
              <li>
                <a href="tel:+526621040617">
                  <FontAwesomeIcon icon="fa-solid fa-phone" />
                  <span> | +52 662 104 0617 | </span>
                </a>
              </li>
              <li>
                <a href="mailto:ventas@proinhmo.com">
                  <FontAwesomeIcon icon="fa-solid fa-envelope" />
                  <span> contacto@proinhmo.com</span>
                </a>
              </li>
            </ul>

            <ul className="list-inline pull-right top-bar-social">
              <li>
                <span>
                  <span className="fi fi-mx" style={{ marginRight: "4px" }}></span> ES </span>
                {/* Botón Switch para el idioma */}
                <div className="switch">
                  <input
                    type="checkbox"
                    checked={i18n.language === "en"}
                    onChange={toggleLanguage}
                    id="language-toggle"
                  />
                  <label htmlFor="language-toggle">

                  </label>
                </div>
                <span>
                  <span className="fi fi-us" style={{ marginLeft: "6px" }}></span> EN </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="/">
            <img src="img/proin-logo.webp" alt="proin logo" />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li><a href="/" className="page-scroll">{i18n.t('navigation.home')}</a></li>
            <li><a href="/about" className="page-scroll">{i18n.t('navigation.about')}</a></li>
            <li><a href="/services" className="page-scroll">{i18n.t('navigation.services')}</a></li>
            <li><a href="/contact" className="page-scroll">{i18n.t('navigation.contact')}</a></li>
            <li><a href="/catalog" className="page-scroll">{i18n.t('navigation.catalog')}</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
